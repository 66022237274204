(function ($) {
  Drupal.behaviors.footerSectionPrimaryV1 = {
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;

      self.checkBrowserWidth(context);
      Unison.on('change', function () {
        self.checkBrowserWidth(context);
      });
    },
    checkBrowserWidth: function (context) {
      var $template = $('.footer-section-primary-v1', context);
      var bps = Unison.fetch.all();
      var bp = Unison.fetch.now();
      var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);

      $template.each(function () {
        var $this = $(this);
        var $toggle = $this.find('.js-footer-section-primary__header');
        var $content = $this.find('.js-footer-section-primary__content');
        var $chatLink = $this.find('.js-footer-chat-link');
        var $formatter = $this.parent().parent();

        if (isDesktop) {
          if ($this.hasClass('js-footer-section-primary--mobile-expanded')) {
            $this
              .parent()
              .removeClass('footer-section-primary--mobile-child-expanded')
              .prependTo($formatter);

            return;
          }
          $toggle.removeClass('footer-section-primary--expandable');
          $toggle.attr('aria-expanded', 'false');
          $content.removeClass('footer-section-primary__content--open');
          $toggle.off('click');
        } else {
          // Add a class to parent, move to bottom of the stack
          // then bail out if mobile is set to load always expanded via the CMS
          if ($this.hasClass('js-footer-section-primary--mobile-expanded')) {
            $this
              .parent()
              .addClass('footer-section-primary--mobile-child-expanded')
              .appendTo($formatter);

            return;
          }

          $toggle.addClass('footer-section-primary--expandable');

          function sectionToggle() {
            $toggle
              .attr('aria-expanded', $toggle.attr('aria-expanded') === 'true' ? 'false' : 'true')
              .toggleClass('footer-section-primary__header--open');
            $content.toggleClass('footer-section-primary__content--open');
          }

          // Toggle self open and close
          $toggle.off('click').on('click', function (e) {
            e.preventDefault();
            sectionToggle();
          });
        }

        $chatLink.once().on('click touchstart', function (e) {
          e.preventDefault();
          $('[data-lp-event]', context).trigger('click');
        });
      });
    },
    attached: false
  };
})(jQuery);
